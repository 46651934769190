/* You can add global styles to this file, and also import other style files */
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/saga-blue/theme.css";
//@import "primeng/resources/themes/mdc-light-indigo/theme.css";
//@import "primeng/resources/themes/mdc-light-indigo/theme.css";
//@import "primeng/resources/themes/mdc-dark-deeppurple/theme.css";
@import "primeng/resources/primeng.min.css";
//PRIMEFLEX
@import "primeflex/primeflex.css";

/* @import url('https://fonts.googleapis.com/css2?family=Khand:wght@300;600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Khand:wght@300;600&family=Noto+Sans+JP:wght@100;300;500&display=swap');

.numerohard{    font-family: 'Khand', sans-serif; font-weight: 600;}

.numerosoft{    font-family: 'Khand', sans-serif; font-weight: 300;}

.labeldato100 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 100;
}
.labeldato300 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 300;
}
.labeldato500 {
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 500;
}

body {
  padding: 2em;
}
.container  {
  padding: 1em;
}

.filmes100 {
  width: 100% !important;
  /* border: 5px lime solid !important; */
}

.mainApp{
  max-width: 1400px;
  margin: 0 auto;
  background: rgb(242, 242, 250);
  padding: 0.8em;
}
body{
  background: rgb(56, 56, 56);
}
.p-menuitem-link-active{
  border: 1px red solid;
}


/* .cal-cell-top{
  border-top: 2px crimson solid;
  height: 35px !important;
} */

.only-icon{

  input#monthpicker {
    display: none;
  }
}


.capital {
  text-transform: capitalize;
}
